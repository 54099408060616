import React from 'react';
import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import FlexCard from '../../components/motorrad/flexcard';
import GiftCardBackground from '../../components/giftcardbackground';
import settings from '../../../settings';


const topbanner = `${settings.IMAGES_BASE_URL}/images/motorrad/giftcards/moto-banner.jpg`;
const giftcardimage = `${settings.IMAGES_BASE_URL}/images/motorrad/giftcards/moto-gift-card.jpg`;
const GiftCards = () => {
  return (
    <Layout>
      <SEO
        title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
        description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
      />
      <div className="giftcardpage giftcardpagemotorrad">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />
          <section className="drivingschool">
            <div className="container">
              <div className="performance-center__header">
                <h2>Gift Cards</h2>
              </div>
            </div>
            <FlexCard
              rowType="row"
              image={giftcardimage}
              imageAlt="GIVE THE GIFT OF SPEED"
              header="GIVE THE GIFT OF SPEED"
              subheader="BMW U.S. RIDER ACADEMY GIFT CARDS"
              text="Give someone a driving gift they’ll never forget. Good toward any class, each BMW gift card is delivered electronically, so it’s also perfect for last-minute gift giving."
              btnText="Call 888-345-4269 to get yours now"
              btnStyle="btn-blue"
            >
              <a href='tel:888-345-4269' className='phone-number' target="_blank" rel="noopener noreferrer">
                <button className="btn btn-bordered">call 888-345-4269 to get yours now</button>
              </a>
            </FlexCard>
          </section>
        </div>
    </Layout>
  );
};

export default GiftCards;
